.App {
  text-align: center;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  src: url('./pages/layout/font/Montserrat-Regular.ttf');
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

